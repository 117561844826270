import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
//STYLES
//import './index.css';
import { GlobalStyle } from './utils/globalStye';
import { ThemeProvider } from "styled-components"
import { theme } from './utils/theme';

//PAGES
import App from './App';
import Login from './pages/Login/login';
import Main from './pages/main/main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {/* <App /> */}
      {/* <Login/> */}
      <Main/>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
