import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import engo_logo from './../../assets/engo_logo.png'
//COMPONENTS
import Switch from '../Switch/Switch';

function Card({name, children}) {
    return (
      <>
        {children}
        <h6>{name}</h6>
        {/* <Switch>Włączone</Switch> */}
      </>
    );
  }
  
  export default Card;

