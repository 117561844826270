import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import engo_logo from '../../assets/engo_logo.png'
//COMPONENTS
import Button from '../../components/Button/Button';
import Input  from '../../components/Forms/Input'

const NavbarWrapper = styled.div`
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid ${theme.color.sec6};
    padding: .5rem;
    
    div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`

const Logo = styled.img`
    width: auto;
    height: 100%;
    margin: 0;
    margin-right: 1rem;
    margin-left: 1rem;
`

function Navbar() {
    return (
      <NavbarWrapper>
          <div>
              <Logo src={engo_logo} alt="logo" />
              <h5>Witaj w aplikacji Engo Controls!</h5>
          </div>
            
      </NavbarWrapper>
    );
  }
  
  export default Navbar;