import React from "react"
import styled, {css} from "styled-components"

const variantOptions= {
    base: {
        color_font: "white",
        color_font_hover: "white",
        color: props => props.theme.color.base1,
        color_hover: props => props.theme.color.prim1,
        border: "none",
    },
    white_frame: {
        color_font: "white",
        color_font_hover: props => props.theme.color.prim1,
        color: "transparent",
        color_hover: "white",
        border: "1px solid white",
    },
    white: {
        color_font: "red",
        color: props => props.theme.color.sec1,
        border: "1px solid white",
    },
    arrow: {
      color_font: "red",
      color: props => props.theme.color.sec1,
      border: "1px solid white",
      padding: "1rem 1rem",
    }
}

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding-top: ${props => props.mt || "2rem"};
  padding-bottom: ${props => props.mb || "2rem"};
`

const ButtonAll = `
    display: flex;
    border-radius: 8px;
    justify-self: center;
    align-self: center;
    display: block;
    position: relative;
    text-decoration: none;
    padding: .7rem 4rem ;
    transition: all 0.4s ease-in-out;
    line-height:1.1;
    font-weight: 700;
    font-size: 20px;

    b {
        color: transparent;
      }
    
      :focus {
        border: 1px solid #4f6335;
      }

`
const BtnA = styled.a`
  ${ButtonAll}
  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
        color: ${variantOptions[variant].color_font};
        background-color: ${variantOptions[variant].color};
        border: ${variantOptions[variant].border};
        height: ${variantOptions[variant].width};
        padding: ${variantOptions[variant].padding};
        line-height: ${variantOptions[variant].line_height};

        &:hover {
        color: ${variantOptions[variant].color_font_hover};
        background-color: ${variantOptions[variant].color_hover};
      }
    `}
`

const BtnB = styled.button`
  ${ButtonAll}
  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
        color: ${variantOptions[variant].color_font};
        background-color: ${variantOptions[variant].color};
        border: ${variantOptions[variant].border};
        height: ${variantOptions[variant].width};
        padding: ${variantOptions[variant].padding};
        line-height: ${variantOptions[variant].line_height};

        &:hover {
          color: ${variantOptions[variant].color_font_hover};
          background-color: ${variantOptions[variant].color_hover};
      }
    `}
`

const Button = ({
  children,
  variant,
  href,
  target,
  state,
  onClick,
  mb,
  mt,
}) => (
  <ButtonWrapper mt={mt} mb={mb}>
    { href ? (
      <BtnA
        variant={variant}
        href={href}
        target={target}
      >
        {children}
      </BtnA>
    ) : (
      <BtnB
        variant={variant}
        onClick={onClick}
        state={state}
      >
        {children}
      </BtnB>
    )}
  </ButtonWrapper>
)

export default Button
