import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import engo_logo from './../../assets/engo_logo.png'
//COMPONENTS
import {Label} from '../../utils/typo'
import Switch from '../Switch/Switch';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    min-height: 200px;
    justify-items: stretch;
    align-items: stretch;
    border-radius: 20px;
    font-size: 60px;
    padding: 1rem;
    line-height: 60px; 
    background-color: ${theme.color.sec7};  
    color: ${theme.color.sec3};
    box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.9);
`

function CardWrapper({name, type, children}) {
    return (
      <Wrapper>
        <Label>{type}</Label>
        <h6>{name}</h6>
        {children}
      </Wrapper>
    );
  }
  
  export default CardWrapper;

