import React, { useEffect, useState } from 'react';
//DATA
import axios from 'axios';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import logo from './../../assets/icons/angle.png'
//COMPONENTS
import Button from '../Button/Button';

const TermostatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h6 {
    font-size: 70px;
    text-align: center;
    color: ${theme.color.base1};
    font-weight: 400;
    line-height: 70px;
    margin: 1rem auto;
  }

  p {
      color: ${theme.color.sec4};
      text-align: center;
      //margin-bottom: 0.3rem;
      font-size: 18px;
    }
`

const PanelWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
    column-gap: .5rem;

    p {
      color: ${theme.color.sec4};
      text-align: center;
      font-size: 22px;
    }
`

const ButtonArrow = styled.button`
      border: 2px solid ${theme.color.sec5};
      border-radius: 10px;
      max-width: 50px;
      background-color: transparent;
      padding: 1rem 0.2rem;
      transition: all 0.4s ease-in-out;
      transform: rotate(-90deg);

        &:nth-of-type(2) {
          transform: rotate(90deg);
        }

      img {
        opacity: .3;
        width: 80%;
        margin: 0rem 0;

        &:hover {
        background-color: transparent;
        border-style: none;
        }
      }

      &:hover {
            background-color: ${theme.color.base1};
          }
`

const checkTemp = (device, propertyToCheck) => {

  let tempSet = 22;

  for (const item of device.statusList) {
    if (item.code === propertyToCheck) {
      tempSet = item.value;
      break;
    }
  }

  // console.log(device.name + ", status:  " + switch1Value);
  return tempSet;
};



function ThermostatCard({deviceId, device}) {
// console.log(deviceId + " " + temperature)

const [tempSet, setTempSet] = useState(23);
const [tempCurrent, setTempCurrent] = useState(22);

const _engoThermostatProductId = ["zrk5ctgzhyaupkrn", "awnadkan"];

useEffect(() => {
  setTempSet(checkTemp(device, 'temp_set'));
  setTempCurrent(checkTemp(device, 'temp_current'))
}, []);

const handleArrowUpClick = () => {
  handleTempChange(positiveIncrement);
};

const handleArrowDownClick = () => {
  handleTempChange(negativeIncrement);
};

const handleTempChange = (isNegative) => {
  let tempIncrement = isNegative ? -1 : 1;
  let newTempSet = tempSet + tempIncrement;

  const data = { 
    deviceId: deviceId,
    command: "temp_set",
    value: newTempSet
  }; 

  axios.post('https://engoapi.3devs.pl/api/Devices/ExecuteTempSetCommand', data)
    .then((response) => {
      console.log('Data posted successfully:', response.data);
      setTempSet(newTempSet);
    })
    .catch((error) => {
      console.error('Error posting data:', error);
    });

  // Code to be executed when the component is clicked
  console.log('Component clicked! ' + deviceId);
};

const recalculateTemperature = (temperature) => {
return _engoThermostatProductId.includes(device.productId) ? temperature / 10 : temperature ;
};

const negativeIncrement = true;
const positiveIncrement = false;

    return (
      <TermostatWrapper>
        <h6>{recalculateTemperature(tempCurrent)}°C</h6>
        <p>temperatura zadana</p>
        <PanelWrapper>
          <ButtonArrow onClick={handleArrowUpClick}>
            <img src={logo}/>
          </ButtonArrow>
          <div>
            <p>{recalculateTemperature(tempSet)}°C</p>
          </div>
          <ButtonArrow onClick={handleArrowDownClick}>
          <img src={logo} />
          </ButtonArrow>
        </PanelWrapper>
        
      </TermostatWrapper>
    );
  }
  
  export default ThermostatCard;

