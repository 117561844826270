import React, { useEffect, useState } from 'react';
//DATA
import axios from 'axios';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
//COMPONENTS
import Button from '../Button/Button';
import OnOffButton from '../Button/OnOffButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

function RelayCard({name, deviceId, statusOnOff}) {
  //  console.log(name + ", status:  " + statusOnOff);
   const [isClicked, setIsClicked] = useState([]);

   useEffect(() => {
    setIsClicked(statusOnOff);
  }, []);

  const handleClick = () => {
    const data = { 
      deviceId: deviceId,
      command: "switch_1",
      value: !isClicked
    };

    axios.post('https://engoapi.3devs.pl/api/Devices/ExecuteCommand', data)
      .then((response) => {
        console.log('Data posted successfully:', response.data);
        setIsClicked(!isClicked);
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });

    // Code to be executed when the component is clicked
    console.log('Component clicked! ' + deviceId);
  };

    return (
      <Wrapper>
        <OnOffButton onClick={handleClick} status={isClicked}/>
        {/* <Button onClick={handleClick}>on/off</Button> */}
      </Wrapper>
    );
  }
  
  export default RelayCard;