import React from 'react';
//STYLES

import { breakpoint } from "styled-components-breakpoint"
//DATA

//COMPONENTS
import Layout from '../../layout/Layout';
import DataComponent from './DataComponent';
import Login from '../Login/login';

function Main() {

    return (
      <Layout>
        <DataComponent />
      </Layout>
    );
  }
  
  export default Main;