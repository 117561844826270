import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import home from '../../assets/icons/home.png'
import apps  from '../../assets/icons/apps.png'
import users  from '../../assets/icons/users.png'
import settings  from '../../assets/icons/settings.png'
//COMPONENTS


const NavbarWrapper = styled.div`
    height: 100%;
    box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.1);
`

const FixedElementsWrapper = styled.div`
    height: 100vh;
`

const FixedElements = styled.div`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 55px;
    padding: 1rem;
`

const Logo = styled.img`
    opacity: .3;
    width: auto;
    margin: 1rem 0;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    &:hover {
        opacity: .7;
      }
`

const ButtonPlus = styled.button`
    display: grid;
    justify-items: center;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    border: 2px solid ${theme.color.base1};
    margin: 2rem -7px;
    padding: 0;
    background-color: ${theme.color.sec7};  
    color: ${theme.color.base1};
    transition: all 0.4s ease-in-out;
    //box-shadow: 3px 0px 4px 2px rgba(0, 0, 0, 0.1);

    p {
        line-height: 0;
        align-self: center;
        justify-self: center;
        font-weight: 400;
        font-size: 40px;
        color: ${theme.color.base1};
    }

    &:hover {
        background-color: ${theme.color.base1};
        p {
            color: ${theme.color.base9};
        }
      }
`

function LeftSidePanel({onClick}) {
    return (
      <NavbarWrapper>
          <FixedElementsWrapper>
<FixedElements>
    <Logo src={home} alt="logo" />
            <Logo src={apps} alt="logo" />
            <ButtonPlus onClick={onClick}>
                <p>+</p>
            </ButtonPlus>
            <Logo src={users} alt="logo" />
            <Logo src={settings} alt="logo" />
</FixedElements>
              
          </FixedElementsWrapper>
            
      </NavbarWrapper>
    );
  }
  
  export default LeftSidePanel;