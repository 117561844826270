import React from "react"
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  justify-content: center;
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    color: ${theme.color.sec4};
    text-transform: uppercase;
`

const Inp = styled.input`
    margin: 0;
    padding: 10px 15px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    height: auto;
    outline: 0;
    width: 100%;
    background-color: ${theme.color.sec7};
    color: ${theme.color.sec3};
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
`

const Input = ({
  label_text,
  label_for,
  type,
  value,
  id,
  name,
  maxLength,
  mb,
  mt,
  onChange
}) => (
  <InputWrapper mt={mt} mb={mb}>
      <Label for={label_for}>{label_text}</Label>
      <Inp
        type={type}
        placeholder={value}
        id={id}
        name={name}
        maxLength={maxLength}
        onChange={onChange}/>
  </InputWrapper>
)

export default Input
