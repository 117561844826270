import React from "react"
import styled, {css} from "styled-components"
import { theme } from "../../utils/theme"

const SwitchWrapper = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: row;

    label {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        color: ${theme.color.sec4};
        text-transform: uppercase;
        }
`

const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

      span {
        position: absolute;
         cursor: pointer;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background-color: #333;
         -webkit-transition: 400ms;
         transition: 400ms;
         border-radius: 34px;
            &:before {
               border-radius: 50%;
            }

            &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: whitesmoke;
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            -webkit-transition: 400ms;
            transition: 400ms;
         }
      }
`


const Switch = ({
  children,
  label_for,
  id,
  name,
  checked,
  onChange
}) => (
    <SwitchWrapper>
        <SwitchButton type="checkbox" name={name} id={id}>
            <input
                type="checkbox"
                id={id}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <span/>
        </SwitchButton>
        
        <label for={label_for}>
            {children}
        </label>
    </SwitchWrapper>
  
)

export default Switch
