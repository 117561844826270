import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import engo_logo from './../../assets/engo_logo.png'
//COMPONENTS
import Button from '../../components/Button/Button';
import Input  from '../../components/Forms/Input'


const Layout = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
`
const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 5rem;

    p {
        ${breakpoint('md')`
        font-size: 10px;
    `}
    }
`

const InputWrapper = styled.div`
    display: grid;
    row-gap: 1.5rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
`

const SideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: rgb(149,193,31);
background: linear-gradient(146deg, rgba(149,193,31,1) 0%, rgba(87,87,86,1) 100%);
    height: 100vh;
    padding: 5rem;
    p, h2 {
        color: white;
    }
    p {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
`

const Logo = styled.img`
    width: 30%;
    margin-bottom: 5rem;
    justify-self: flex-start;
`

function Login() {
    return (
      <Layout>
            <LoginWrapper><Logo src={engo_logo} alt="logo" />
        
                <h3>Logowanie</h3>
                <p>Witamy w panelu sterowania Engo Controls.<br/>
                Zaloguj się, aby zobaczyć swoje urządzenia.</p>
                <InputWrapper>
                    <Input 
                        label_for='name'
                        label_text='Nazwa użytkownika'
                        type="text" 
                        value="nazwa" 
                        id="name" 
                        name="name" 
                        maxlength="40"/>

                    <Input 
                        label_for='email'
                        label_text='Email'
                        type="email" 
                        value="nazwa@mail.com" 
                        id="email" 
                        name="email" 
                        maxlength="40"/>

                    <Input 
                        label_for='password'
                        label_text='Hasło'
                        type="password" 
                        value="Hasło" 
                        id="password" 
                        name="password" 
                        maxlength="40"/>

                    <Button variant="base">
                        Zaloguj się
                    </Button>
                </InputWrapper>
        
                </LoginWrapper>
        <SideWrapper>
            <h2 colorFont="white">Witaj!</h2>
            <p>Jeżeli jesteś nowym użytkownikiem Engo Controls, załóż nowe konto, aby móc cieszyć się naszym oprogramowaniem.</p>
            <Button 
                variant="white_frame">
                Zarejestruj się
            </Button>
        </SideWrapper>
      </Layout>
    );
  }
  
  export default Login;