import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../utils/theme';
import { size } from "../utils/breakpoints"
//DATA

//COMPONENTS
import LeftSidePanel from '../layout/LeftSidePanel/LeftSidePanel'
import Navbar from './Navbar/Navbar';

const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 55px 1fr;
    background-color: ${theme.color.sec7};
    overflow: hidden;
`



function Layout({children, onClick}) {
    return (
      <LayoutWrapper>
        <LeftSidePanel
            onClick={onClick}/>
        <div>
            <Navbar/>
            <div>
                {children}
            </div>
        </div>
      </LayoutWrapper>
    );
  }
  
  export default Layout;