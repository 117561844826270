import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { size } from "../../utils/breakpoints"
// AXIOS
import axios from 'axios';
import Card from '../../components/Card/Card';
import ThermostatCard from '../../components/Card/ThermostatCard';
import RelayCard from '../../components/Card/RelayCard';
//COMPONENTS
import CardWrapper from '../../components/Card/CardWrapper';
import SerchFilterPanel from './SerchFilterPanel';

const Elements = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    padding: 2rem;

    @media ${size.xl} {
        grid-template-columns: repeat(5, 1fr);
    }

    @media ${size.l} {
        grid-template-columns: repeat(4, 1fr);
    }

    @media ${size.md} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${size.sm} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${size.xs} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const DataComponent = () => {
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState([]);
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://engoapi.3devs.pl/api/Devices/GetList');
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const checkRelayState = (device) => {

      let switch1Value = false;

      for (const item of device.statusList) {
        if (item.code === 'switch_1') {
          switch1Value = item.value;
          break;
        }
      }

      // console.log(device.name + ", status:  " + switch1Value);
      return switch1Value;
    };

    const handleInputChange = (event) => {
      console.log("handleInputChange")
      setInputValue(event.target.value);
    };
  
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(inputValue)
    );
  
    return (
      <>
        <SerchFilterPanel onChangeCallback={ handleInputChange}/>
        <Elements>
          {/* Display the fetched data */}
          {filteredData.map((device) =>
          {
              switch (device.deviceType) {
                  case 1:
                      return (
                      <CardWrapper 
                        key={device.id} 
                        name={device.name} 
                        type="termostat">
                      <ThermostatCard key={device.id} deviceId={device.id} device={device} />
                      </CardWrapper>
                      )
                  case 2:
                      return (                  

                        <CardWrapper
                        key={device.id}
                        name={device.name}
                        type="Gniazdko"                      >
                          <RelayCard  key={device.id} name={device.name} deviceId={device.id} statusOnOff={checkRelayState(device)}/>
                        </CardWrapper>
                      )
                  default:
                      return (
                      <CardWrapper
                        key={device.id} 
                        name={device.name}
                        type="Inny"
                        >
                        <Card/>
                      </CardWrapper>
                      )
                }
          })}
        </Elements>
      </>
    );
  };
  
  export default DataComponent;