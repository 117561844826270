import React from 'react';
//STYLES
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { breakpoint } from "styled-components-breakpoint"
//DATA
import search from './../../assets/icons/search.png'
import sort from './../../assets/icons/sort.png'
//COMPONENTS
import Input from '../../components/Forms/Input';

const Wrapper = styled.div`
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    >div {
        display: grid;
        grid-template-columns: 30px 1fr;
        column-gap: 5px;
        align-items: center;
    }

    img {
        opacity:.2;
        height: 24px;
    }

    input {
        background-color: ${theme.color.sec6};
    }
`

function SerchFilterPanel({onChangeCallback}) {
    return (
      <Wrapper>
        <div>
            <img alt='wyszukaj' src={search}/>
            <Input
            label_for='name'
            label_text=''
            type="text" 
            value="Wyszukaj po nazwie lub ID" 
            id="name" 
            name="name" 
            onChange={ onChangeCallback}
            maxlength="40"/>
        </div>
        <div>
        <img alt='wyszukaj' src={sort}/>
            <Input
            label_for='name'
            label_text=''
            type="text" 
            value="Sortuj po nazwie" 
            id="name" 
            name="name" 
            maxlength="40"/>
        </div>
      </Wrapper>
    );
  }
  
  export default SerchFilterPanel;

